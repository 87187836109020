import { AsyncPipe } from '@angular/common';
import { Component, HostBinding, signal, Signal, WritableSignal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxErrorsModule } from '@ngspot/ngx-errors';
import { TranslateModule } from '@ngx-translate/core';
import { I18nService } from '@studiohyperdrive/ngx-i18n';
import { catchAndCallThrough, ObservableArray, ObservableBoolean, validateContent } from '@studiohyperdrive/rxjs-utils';
import { takeUntil, tap } from 'rxjs/operators';

import { CompanyEntity, CompanyService, CompanyInfoComponent } from '@cjm/shared/company';
import { BrowserService, SessionService } from '@cjm/shared/core';
import { VLoketAppRoutePaths } from '@cjm/shared/route-paths';
import { CJMError, OnDestroyComponent } from '@cjm/shared/types';
import {
	ButtonClasses,
	AlertType,
	ButtonComponent,
	CJMContentComponent,
	FaIconComponent,
	AlertComponent
} from '@cjm/shared/ui/common';
import { ModalDialogService } from '@cjm/shared/ui/modal';
import { generateSnackbarConfig, SnackBarComponent, SnackBarService } from '@cjm/shared/ui/toast';
import { formatCJMErrorDetails } from '@cjm/shared/utils';
import { AssociationApiService, IMainActivity } from '@cjm/v-loket/repositories';
import { CheckboxGroupComponent } from '@cjm/v-loket/shared';

import { RegisterEaAPIService, RegisterEaFacade } from '../../../data';
import { I18nKeys } from '../../../i18n';
import { IEARegistration } from '../../pages/register-ea/register-ea.types';

@Component({
	templateUrl: './register-kbo-modal.component.html',
	styleUrls: ['./register-kbo-modal.component.scss'],
	providers: [RegisterEaAPIService, AssociationApiService, RegisterEaFacade],
	standalone: true,
	imports: [
		CJMContentComponent,
		ReactiveFormsModule,
		FormsModule,
		NgxErrorsModule,
		CheckboxGroupComponent,
		ButtonComponent,
		CompanyInfoComponent,
		FaIconComponent,
		AsyncPipe,
		TranslateModule,
		AlertComponent
	]
})
export class RegisterKboModalComponent extends OnDestroyComponent {
	@HostBinding('class.c-welcome-register-kbo-modal') private readonly hasRootClass: boolean = true;

	public readonly companyLoading$: ObservableBoolean = this.companyService.companyLoading$;
	public readonly mainActivities$: ObservableArray<IMainActivity> = this.registerFacade.getMainActivities();
	public readonly company: Signal<CompanyEntity> = toSignal(this.companyService.company$.pipe(validateContent()));
	public readonly errorDetails: WritableSignal<string> = signal<string>(null);

	public readonly i18nKeys: typeof I18nKeys = I18nKeys;
	public readonly appRoutePaths: typeof VLoketAppRoutePaths = VLoketAppRoutePaths;
	public readonly buttonClasses: typeof ButtonClasses = ButtonClasses;
	public readonly mainActivitiesControl: FormControl = new FormControl(null);
	public companyNumber: string;

	constructor(
		private readonly registerFacade: RegisterEaFacade,
		private readonly companyService: CompanyService,
		private readonly snackBarService: SnackBarService,
		private readonly sessionService: SessionService,
		private readonly modalService: ModalDialogService,
		private readonly i18nService: I18nService,
		private readonly router: Router,
		private readonly browserService: BrowserService
	) {
		super();
	}

	protected readonly ButtonClasses = ButtonClasses;

	/**
	 * validateAndSubmit
	 *
	 * The validateAndSubmit method will check if the user has selected any main activities.
	 * If not, a warning will be shown.
	 * If so, the KBO organisation will register.
	 */
	public validateAndSubmit(): void {
		this.mainActivitiesControl.markAsDirty();
		this.mainActivitiesControl.updateValueAndValidity();

		if (this.mainActivitiesControl.invalid) {
			this.snackBarService.openFromComponent(
				SnackBarComponent,
				generateSnackbarConfig({
					title: this.i18nService.getTranslation(
						this.i18nKeys.Registration.RegisterEa.FormNotice.InvalidFormTitle
					),
					message: this.i18nService.getTranslation(
						this.i18nKeys.Registration.RegisterEa.FormNotice.InvalidForm
					),
					type: AlertType.Warning
				})
			);
			this.browserService.scrollIntoView('.c-input__description.is-error');

			return;
		}

		this.registerFacade
			.submitRegistration({
				basicInfo: {
					mainActivities: this.mainActivitiesControl.getRawValue()
				}
			} as IEARegistration)
			.pipe(
				tap(() => {
					this.modalService.instantCloseModal();

					this.router.navigate([
						'/',
						this.i18nService.currentLanguage,
						this.appRoutePaths.Registration,
						this.appRoutePaths.RegistrationRedirectRegistration
					]);
				}),
				catchAndCallThrough(({ error }: { error: CJMError }) => {
					this.errorDetails.set(formatCJMErrorDetails(error));
				}, 'throw'),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	/**
	 * closeModal
	 *
	 * The closeModal method will hide the modal until the page is revisited.
	 */
	public closeModal(companyNumber: string): void {
		this.sessionService.setSessionItem(`hide-register-kbo-modal.${companyNumber}`, true);

		this.modalService.instantCloseModal();
	}
}
