@if (associationData$ | async; as associationData) {
	<cjm-layout-container
		class="c-vloket-success__container"
		layoutType="full-width"
		asidePosition="right"
		alignItems="end"
		[preventSpacing]="['top', 'right']"
	>
		<ng-container main>
			<h1 class="c-vloket-header c-vloket-header--h1">
				<span
					class="c-vloket-text-marking c-vloket-text-marking--line-through"
					[innerHTML]="
						(associationData.associationType.code === associationCode.FV
							? i18nKeys.Registration.Success.Title.Association
							: i18nKeys.Registration.Success.Title.KBOAssociation
						) | translate
					"
				>
				</span>
			</h1>
			@if (associationData.associationType.code === associationCode.FV) {
				<div
					class="c-vloket-text--lg u-margin-bottom-xl"
					[innerHTML]="i18nKeys.Registration.Success.BodyHTML | translate"
				></div>
			}
			<cjm-alert
				[alertMessage]="i18nKeys.Registration.Success.DelayNotice | translate"
				[alertType]="alertType.Info"
				[isClosable]="false"
			/>
			<vloket-association-card classes="u-margin-top-xl u-margin-bottom-lg" [association]="associationData" />
			@if (myAssociationPercentage$ | async; as percentage) {
				<vloket-progress-bar
					[title]="
						i18nKeys.Registration.Success.ProgressBarTitle
							| translate: { number: percentage, association: associationData.names | associationName }
					"
					[progress]="percentage"
				/>
			}
			@if (myAssociationProgress$ | async; as tasks) {
				<vloket-icon-list class="u-margin-top-lg" [items]="tasks" />
			}
			@if (
				associationData.associationType.code === associationCode.FV ||
				associationData.associationType.code === associationCode.AFD
			) {
				<div class="u-margin-top-xl c-vloket-success__cta-buttons">
					<cjm-button
						cjmUserEIDLogin
						[withWarning]="true"
						[warningLabels]="authRedirectFVDataWarning"
						[callback]="associationDetailsCallbackUrl"
						[capHint]="acmTargetGroups.VER"
						[codeHint]="associationData.associationNumber"
						[classes]="buttonClasses.RegularFullWidth + ' ' + 'c-button--blue'"
						[title]="i18nKeys.Registration.Success.CtaButtons.ToMyAssociations.Title | translate"
					>
						{{ i18nKeys.Registration.Success.CtaButtons.ToMyAssociations.Text | translate }}
					</cjm-button>
					<cjm-button
						cjmUserEIDLogin
						[withWarning]="true"
						[warningLabels]="authRedirectFVProductsWarning"
						[callback]="ProductsCallbackUrl"
						[capHint]="acmTargetGroups.VER"
						[codeHint]="associationData.associationNumber"
						[classes]="buttonClasses.OutlineFullWidth + ' ' + 'c-button--blue-outline'"
						[title]="i18nKeys.Registration.Success.CtaButtons.ToProducts.Title | translate"
					>
						{{ i18nKeys.Registration.Success.CtaButtons.ToProducts.Text | translate }}
					</cjm-button>
				</div>
			} @else {
				<div class="u-margin-top-xl c-vloket-success__cta-buttons">
					<cjm-button
						cjmUserEIDIdentitySwitch
						[withWarning]="true"
						[warningLabels]="authRedirectKBODataWarning"
						[callback]="associationDetailsCallbackUrl"
						[classes]="buttonClasses.RegularFullWidth + ' ' + 'c-button--blue'"
						[title]="i18nKeys.Registration.Success.CtaButtons.ToMyAssociations.Title | translate"
					>
						{{ i18nKeys.Registration.Success.CtaButtons.ToMyAssociations.Text | translate }}
					</cjm-button>
					<cjm-button
						cjmUserEIDIdentitySwitch
						[withWarning]="true"
						[warningLabels]="authRedirectKBOProductsWarning"
						[callback]="ProductsCallbackUrl"
						[classes]="buttonClasses.OutlineFullWidth + ' ' + 'c-button--blue-outline'"
						[title]="i18nKeys.Registration.Success.CtaButtons.ToProducts.Title | translate"
					>
						{{ i18nKeys.Registration.Success.CtaButtons.ToProducts.Text | translate }}
					</cjm-button>
				</div>
			}
		</ng-container>
	</cjm-layout-container>
}
<div class="c-vloket-success__visual"></div>
